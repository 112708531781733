import Layout from "components/layout";
import Image from "next/image";
import Link from "next/link";
import SubscriptionTemplate from "components/pages/assinatura/subscription_template";

import styles from "./index.module.scss";
import { FiList, FiDollarSign } from "react-icons/fi";
import clsx from "clsx";
import { isPwa } from "utils/browser";

import { useUser } from "hooks/user";
import { useMounted } from "hooks/react";

export default function Home(): JSX.Element {
  useUser("/plano/tarefas", true);
  const mounted = useMounted();
  const renderAppDownloadButton = !mounted || !isPwa();

  return (
    <Layout
      title="Preparatório gratuito para o ENEM e vestibular 2025"
      description="Preparatório gratuito para o ENEM e vestibular 2025. Resolva simulados, assista aulas e acompanhe seu desempenho através de gráficos de performance. Revise seus simulados e se prepare-se para a prova."
      canonical="/"
    >
      <main className={`main p-0 !pb-0 ${styles.main}`}>
        <section>
          <div
            className={`${styles.hero} container flex flex-col items-center p-5 md:px-0 md:flex-row`}
          >
            <div className="content md:max-w-[50%] flex flex-col">
              <h1 className="mb-0 text-center text-4xl font-bold md:text-left md:text-6xl md:leading-tight">
                Estude <span className="text-primary-700">agora</span>{" "}
                <br className="md:hidden" />
                para o ENEM e vestibulares
              </h1>
              <p className="mt-6 text-center text-emphasis-medium text-lg md:text-left">
                Acompanhe seu desempenho com{" "}
                <Link href="/desempenho">
                  <a className="text-emphasis-medium text-lg">
                    gráficos de performance
                  </a>
                </Link>
              </p>

              <div className="actions md:md:max-w-xs flex flex-col mt-4 w-full">
                <Link href="/questoes">
                  <a className="btn btn-primary mb-4">Fazer simulado</a>
                </Link>
                <Link href="#questionnaires">
                  <a className="btn btn-secondary !py-1 flex items-center justify-center">
                    Saber mais
                  </a>
                </Link>

                {renderAppDownloadButton && (
                  <div className="mt-6 mx-auto md:mt-8 md:mx-0">
                    <a href="https://play.google.com/store/apps/details?id=com.vstiba.www.twa&utm_source=website&utm_campaign=landing_page&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                      <Image
                        alt="Disponível no Google Play"
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png"
                        width={193}
                        height={75}
                        priority={true}
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="md:w-[45%] mt-8">
              <div className="md:hidden">
                <Image
                  src="/images/garota-usando-vstiba-computador-mobile.png"
                  alt="Garota usando o Vstiba no computador"
                  width={500}
                  height={625}
                  priority={true}
                  quality={100}
                />
              </div>
              <div className="hidden md:block">
                <Image
                  src="/images/garota-usando-vstiba-computador.png"
                  alt="Garota usando o Vstiba no computador"
                  width={800}
                  height={860}
                  priority={true}
                  quality={100}
                />
              </div>
            </div>
          </div>
        </section>

        <Section
          title="Plano de estudos"
          reverse={true}
          content={
            <>
              <p className="text-lg md:text-xl">
                Organizamos tudo em matérias e assuntos para você não se perder!
              </p>

              <p className="mt-0 text-lg md:text-xl">
                Encontre rapidamente qualquer aula ou exercício e acompanhe o
                andamento dos estudos de cada tópico que você precisa estudar
                para o ENEM ou vestibular.
              </p>

              <div className="actions flex flex-col mt-6 md:max-w-xs">
                <Link href="/plano">
                  <a className="btn btn-tertiary flex items-center justify-center">
                    Personalizar plano
                  </a>
                </Link>
              </div>
            </>
          }
          image={
            <>
              <div className="md:hidden">
                <Image
                  src="/images/captura-plano-biologia.png"
                  alt="Captura de tela do plano de estudos"
                  width={500}
                  height={575}
                  quality={100}
                />
              </div>
              <div className="hidden md:block">
                <Image
                  src="/images/captura-plano-biologia-desktop.png"
                  alt="Captura de tela do plano de estudos"
                  width={800}
                  height={800}
                  quality={100}
                />
              </div>
            </>
          }
        />

        <Section
          id="questionnaires"
          title="Simulados"
          content={
            <>
              <p className="text-lg md:text-xl">
                Teste seu conhecimendo respondendo o mesmo tipo de questões que
                encontrará na hora da prova.
              </p>

              <p className="mt-0 text-lg md:text-xl">
                Responda provas inteiras ou filtre pelas disciplinas do seu
                interesse.
              </p>

              <div className="actions flex flex-col mt-6 md:max-w-xs">
                <Link href="/questoes">
                  <a className="btn btn-tertiary flex items-center justify-center">
                    Fazer simulado
                  </a>
                </Link>
              </div>
            </>
          }
          image={
            <>
              <div className="md:hidden">
                <Image
                  src="/images/captura-simulado.png"
                  alt="Captura de tela de um simulado"
                  width={500}
                  height={500}
                  quality={100}
                />
              </div>
              <div className="hidden md:block">
                <Image
                  src="/images/captura-simulado-desktop.png"
                  alt="Captura de tela de um simulado"
                  width={500}
                  height={500}
                  quality={100}
                />
              </div>
            </>
          }
        />

        <Section
          id="question-chat"
          title="Chat de dúvidas"
          reverse={true}
          content={
            <>
              <p className="text-lg md:text-xl">
                Tire suas dúvidas sobre as questões usando nossa inteligência
                artificial.
              </p>

              <p className="mt-0 text-lg md:text-xl">
                Pergunte sobre conceitos, solicite explicações detalhadas das
                alternativas ou esclareça dúvidas específicas em tempo real, a
                qualquer hora.
              </p>

              <div className="actions flex flex-col mt-6 md:max-w-xs">
                <Link href="/questoes">
                  <a className="btn btn-tertiary flex items-center justify-center">
                    Experimentar agora
                  </a>
                </Link>
              </div>
            </>
          }
          image={
            <div className="p-6">
              <div className="rounded-xl shadow-xl overflow-hidden">
                <Image
                  src="/images/captura-chat-questao.png"
                  alt="Captura de tela do chat de dúvidas sobre questões"
                  width={836}
                  height={1038}
                  quality={100}
                />
              </div>
            </div>
          }
        />

        <Section
          title="Curadoria de aulas"
          reverse={false}
          content={
            <>
              <p className="text-lg md:text-xl">
                Selecionamos e categorizamos as melhores videoaulas do YouTube
                para você ter tudo organizado e nunca se perder!
              </p>

              <p className="mt-0 text-lg md:text-xl">
                Saiba quais aulas já assistiu e tenha sugestões de próximas para
                ver.
              </p>

              <div className="actions flex flex-col mt-6 md:max-w-xs">
                <Link href="/aulas">
                  <a className="btn btn-tertiary flex items-center justify-center">
                    Assistir aulas
                  </a>
                </Link>
              </div>
            </>
          }
          image={
            <>
              <div className="md:hidden">
                <Image
                  src="/images/captura-aulas.png"
                  alt="Captura de tela das videoaulas da plataforma"
                  width={500}
                  height={590}
                  quality={100}
                />
              </div>
              <div className="hidden md:block">
                <Image
                  src="/images/captura-aulas-desktop.png"
                  alt="Captura de tela das videoaulas da plataforma"
                  width={800}
                  height={820}
                  quality={100}
                />
              </div>
            </>
          }
        />

        <Section
          title="Desempenho"
          reverse={true}
          content={
            <>
              <p className="text-lg md:text-xl">
                Acompanhe seu progresso com gráficos de performance e ganhe
                sugestões de como melhorar!
              </p>

              <p className="mt-0 text-lg md:text-xl">
                O panorama exato dos seus estudos.
              </p>

              <div className="actions flex flex-col mt-6 md:max-w-xs">
                <Link href="/desempenho">
                  <a className="btn btn-tertiary flex items-center justify-center">
                    Visualizar gráficos
                  </a>
                </Link>
              </div>
            </>
          }
          image={
            <>
              <div className="md:hidden md:mr-8">
                <Image
                  src="/images/captura-desempenho.png"
                  alt="Captura de tela dos gráficos de desempenho"
                  width={500}
                  height={570}
                  quality={100}
                />
              </div>
              <div className="hidden md:block md:mr-8">
                <Image
                  src="/images/captura-desempenho-desktop.png"
                  alt="Captura de tela dos gráficos de desempenho"
                  width={800}
                  height={1025}
                  quality={100}
                />
              </div>
            </>
          }
        />

        <section>
          <div className="container flex flex-col items-center px-5 py-10 md:px-0">
            <div className="flex flex-wrap items-stretch justify-center">
              <Card className="flex flex-col">
                <span className="text-lg font-semibold uppercase">
                  Modo prova
                </span>
                <p className="flex flex-col flex-grow justify-between mb-0 text-lg">
                  Faça o simulado como se fosse o dia da prova, com limite de
                  tempo e sem ver as respostas antes de terminar.
                  <Link href="/questoes">
                    <a className="block mt-4 text-lg">Resolver prova</a>
                  </Link>
                </p>
              </Card>

              <Card>
                <span className="text-lg font-semibold uppercase">
                  Simulados salvos
                </span>
                <p className="mb-0 text-lg">
                  Salvamos todos os seus simulados para acessar quando quiser!
                  Revise para saber onde errou e acompanhe as estatísticas.
                  <Link href="/meus-simulados">
                    <a className="block mt-4 text-lg">Revisar simulados</a>
                  </Link>
                </p>
              </Card>

              <Card>
                <span className="text-lg font-semibold uppercase">
                  Computador ou celular?
                </span>
                <p className="mb-0 text-lg">
                  Acesse todos os recursos da plataforma onde quiser.
                  Computador, celular ou tablet!
                </p>
              </Card>
            </div>
          </div>
        </section>

        <div className="p-5">
          <SubscriptionTemplate />
        </div>

        <section className="container mt-10 p-5 py-6 md:px-0 md:py-20">
          <h3 className="mb-10 text-emphasis-medium text-2xl">
            Perguntas frequentes
          </h3>

          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="md:mr-20 md:w-1/2">
              <div>
                <h4 className="text-xl font-semibold">Como usar o Vstiba?</h4>
                <p>
                  Fizemos o Vstiba pensando nos alunos que sentem falta de
                  recursos mais robustos para praticar ou revisar conteúdos, por
                  isso recomendamos que você use nossa plataforma para resolver
                  exercícios do conteúdo aprendido na sua escola e assista as
                  videoaulas para lembrar de algo que deixou passar. Use os
                  gráficos de performance para ter as rédeas do seus estudos e
                  intensificar esforços onde for preciso!
                </p>
              </div>
            </div>

            <div className="md:w-1/2">
              <h4 className="text-xl font-semibold">
                O Vstiba substitui curso presencial?
              </h4>
              <p>
                Não! Nossa missão é mais modesta e nosso objetivo é oferecer o
                melhor apoio tecnológico para um curso preparatório presencial,
                seja um cursinho comum ou as aulas do ensino médio. A ideia é
                oferecer pra você recursos digitais que complementem as aulas
                que você tem na escola, para que você tenha mais controle sobre
                o seus estudos e acompanhe seu progresso nessa jornada para o
                vestibular! Isso não significa que o Vstiba não vai ter
                utilidade pra você se não estiver fazendo um curso presencial,
                muito pelo contrário! Você ainda pode usar nossos recursos para
                aprender e, principalmente, praticar para fazer o vestibular ou
                ENEM.
              </p>
            </div>
          </div>
        </section>

        <footer className="container p-5 md:mb-4 md:p-0">
          <ul className="flex flex-col gap-2">
            <li>
              <Link href="/privacidade">
                <a>Política de privacidade</a>
              </Link>
            </li>
            <li>
              <Link href="/termos">
                <a>Termos de uso</a>
              </Link>
            </li>
          </ul>
        </footer>
      </main>
    </Layout>
  );
}

function Section(props) {
  const { id, className } = props;
  const { title, content, image } = props;
  const { reverse } = props;

  const flexDirection = reverse
    ? "flex-col md:flex-row-reverse"
    : "flex-col md:flex-row";
  const bgColor = reverse ? "bg-primary-100" : "bg-white";

  return (
    <section
      id={id}
      className={`${
        className || ""
      } ${bgColor} flex items-center md:max-h-[768px]`}
      style={{ backgroundColor: reverse ? "rgba(255,202,40,.8)" : "white" }}
    >
      <div
        className={`container flex ${flexDirection} items-center p-5 py-10 box-border md:py-16 md:px-0 md:flex md:justify-between`}
      >
        <div className="content md:max-w-[45%] flex flex-col">
          <h2 className="mb-2 text-3xl font-semibold md:text-5xl md:leading-tight">
            {title}
          </h2>

          {content}
        </div>
        <div
          className={`mt-8 ${
            reverse ? "md:mr-16" : "md:ml-16"
          } md:mt-0 md:w-[45%]`}
        >
          {image}
        </div>
      </div>
    </section>
  );
}

function Card(props) {
  const { money, className } = props;

  return (
    <div
      className={clsx(
        "card md:w-[350px] relative mb-8 p-5 w-full bg-white rounded-xl shadow-lg overflow-hidden md:m-4",
        className
      )}
    >
      <div
        className={`circle ${
          money ? "bg-success" : "bg-primary-400"
        } h-24 w-24 rounded-full absolute -right-10 -top-10`}
      >
        <div className="top-[53px] right-[53px] absolute">
          {(money && <FiDollarSign size={22} />) || <FiList size={22} />}
        </div>
      </div>
      {props.children}
    </div>
  );
}
