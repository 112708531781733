import { useUser } from "hooks/user";
import { useMounted } from "hooks/react";
import Link from "next/link";

export default function SubscriptionTemplate(): JSX.Element {
  const { user } = useUser();
  const buttonUrl = "/cadastro?redirect_to=/assinatura";

  const mounted = useMounted();

  return (
    <div className="w-[40rem] mx-auto max-w-full md:mt-12">
      {(mounted && user && (
        <div>
          <h1 className="block text-center text-emphasis-high text-5xl font-bold">
            Recursos exclusivos para o seu sucesso
          </h1>
          <p className="text-center text-emphasis-medium">
            Com o <b>Vstiba Total</b>, você tem acesso a recursos premium que
            impulsionam seu aprendizado, incluindo o Chat de Questões que
            esclarece suas dúvidas com{" "}
            <Link href="/limites/chat">
              <a className="text-amber-600 hover:text-amber-700 underline">
                100 mensagens por dia
              </a>
            </Link>
            .
          </p>
          <p className="text-center text-emphasis-medium">
            Maximize sua preparação com ferramentas avançadas que transformam
            seu estudo e aumentam suas chances de aprovação no ENEM e nos
            vestibulares.
          </p>

          {/* @ts-ignore */}
          <stripe-pricing-table
            pricing-table-id={process.env.NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID}
            publishable-key={process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}
            client-reference-id={user.id}
          >
            {/* @ts-ignore */}
          </stripe-pricing-table>
        </div>
      )) || (
        <div className="w-[40rem] mx-auto max-w-full md:mt-12">
          <h1 className="block text-center text-emphasis-high text-5xl font-bold">
            Recursos exclusivos para o seu sucesso
          </h1>
          <p className="text-center text-emphasis-medium">
            Com o <b>Vstiba Total</b>, você tem acesso a recursos premium que
            impulsionam seu aprendizado, incluindo o Chat de Questões que
            esclarece suas dúvidas com{" "}
            <Link href="/limites/chat">
              <a className="text-amber-600 hover:text-amber-700 underline">
                100 mensagens por dia
              </a>
            </Link>
            .
          </p>
          <p className="text-center text-emphasis-medium">
            Maximize sua preparação com ferramentas avançadas que transformam
            seu estudo e aumentam suas chances de aprovação no ENEM e nos
            vestibulares.
          </p>
          <div className="flex flex-col mt-12 mx-auto w-96 max-w-full bg-white border border-gray-100 rounded-xl shadow-lg overflow-hidden">
            <div className="h-100 p-8">
              <span className="block mb-3 text-xl font-semibold">
                Recursos premium para seu desempenho
              </span>
              <span className="block text-emphasis-medium">
                Acesso a todas as funcionalidades exclusivas, como o{" "}
                <span className="font-semibold">Chat para tirar dúvidas</span>{" "}
                das questões com inteligência artificial com{" "}
                <Link href="/limites/chat">
                  <a className="text-amber-600 hover:text-amber-700 underline">
                    limites claros
                  </a>
                </Link>{" "}
                (300/dia, 15/questão) e o{" "}
                <span className="font-semibold">Modo prova</span>.
              </span>
            </div>
            <div className="action h-100 fade-loading p-8 bg-surface">
              <div className="text-center">
                <span className="text-5xl font-bold">R$ 19,90</span>{" "}
                <span className="text-emphasis-medium text-lg">/ mês</span>
              </div>
              <span className="block mb-8 mt-4 text-center text-emphasis-low">
                Cancele a qualquer momento.
              </span>
              <a
                href={buttonUrl}
                className="btn btn-primary fade-loading w-full"
              >
                Assinar agora
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
